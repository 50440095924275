import classnames from 'classnames';
import React, { useEffect, useRef } from 'react';

import { t } from '@/utility/localization';

export const ReadMore = ({
  className,
  children,
  maxHeight,
  showLessOnLeft = false,
  buttonStyling = '',
  withShowLess = false,
  label = t('see-more2')
}) => {
  const contentContainer = useRef(null);
  const [isOverflowing, setIsOverflowing] = React.useState(false);
  const [isExpanded, setIsExpanded] = React.useState(false);
  useEffect(() => {
    if (contentContainer?.current) {
      setIsOverflowing(
        contentContainer?.current?.scrollHeight >
          contentContainer?.current?.clientHeight ||
          contentContainer?.current?.scrollWidth >
            contentContainer?.current?.clientWidth
      );
    }
  }, [contentContainer, children]);

  return (
    <div
      className={classnames([
        'overflow-hidden overflow-ellipsis ',
        className
      ])}>
      <div
        ref={contentContainer}
        style={{
          maxHeight: isExpanded ? '100%' : maxHeight,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          wordBreak: 'break-word'
        }}>
        {children}
      </div>
      {isOverflowing && !isExpanded && (
        <button
          className={`bg-transparent font-semibold text-neutral-10 outline-none ${buttonStyling}`}
          onClick={() => setIsExpanded(true)}>
          {label}
        </button>
      )}

      {isOverflowing && isExpanded && withShowLess && (
        <div className={`${showLessOnLeft ? 'text-left' : 'text-right'}`}>
          <button
            className={`mt-32 bg-transparent font-semibold text-neutral-10  outline-none ${buttonStyling}`}
            onClick={() => setIsExpanded(false)}>
            {t('see-less')}
          </button>
        </div>
      )}
    </div>
  );
};
